@import "colors";

.contact{
    background-color: $second;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    >main{
        background-color: $first;
        width: 70%;
        height: 70%;
        padding: 3rem;
        
        border-radius: 0 200px 200px 0;

        >h1{
            font-weight: bolder;
        }

        >form{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            gap: 1rem;
            

            >input{
                width: 50%;
                height: 2.5rem;
                font-size: 1rem;
                border: 2px solid transparent;
                border-radius: 5px;
                
            }

            >button{
                padding: 0.5rem 1rem;
                border: transparent;
                background-color: $third;
                color: $first;

                &:hover{
                    border: 2px solid $third;
                    background-color: $first;
                    color: $third;
                    cursor: pointer;
                }
            }

            >label {
                font-family: cursive;
                font-weight: 700;
            }
        }
    }
}