@import 'colors';
.footer {
    display: grid;
    grid-template-columns: 4fr 1fr;
    padding: 1rem;
    background-color: $second;
    color: $first;
    border-top: 3px solid $first;
    padding-top: 3rem;

    >div{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    >#links{
        
        align-items: center;
        gap: 1rem;

        >a{
            color: $first;
            font-family: cursive;
            &:hover{
                color: $third;
            }
        }

        >h4{
            font-weight: 500;
            font-size: 1.5rem;
        }
    }
}