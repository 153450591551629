@import 'colors';
.home{
    background-color: $second;
    height: 100vh;
    width: 100%;
    >main{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 2rem;
        >h1{
            font-weight: 500;
            color: $first;
            font-size: 6rem;
        }
        >p{
            font-weight: 900;
            color: $first;
            font-size: 1rem;

        }
    }
    &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background:no-repeat center/cover url(../assets/1.png);
        opacity: 0.8;
       
    }

    
}

.home2{
    width: 100%;
    height: 100vh;
    background-color: $second;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 500px 0px;
    margin-top: -200px;

    > img {
        width: 30%;
        animation: imgUp 0.7s linear infinite alternate ;
    }

    >div{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5rem;

        >p{
            color: $first;
            font-weight: 100;
            font-family: $text2;
            letter-spacing: 2px;
            word-spacing: 5px ;
        }
    }
}


@keyframes imgUp {
    to{
        transform: translateY(-30px);
    }    
}

.home3{
    width: 100%;
    height: 120vh;
    margin-top: -200px;
    background-color: $second;
    display: flex;
    

    >div{
        width: 70%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        justify-content: center;
        align-items: center;
        background-color: $first;
        border-radius: 0 200px 200px 0;
        gap: 2rem;

        >p{
            letter-spacing: 1px;
            word-spacing: 5px ;
            line-height: 170%;
            font-style: italic;
            padding: 5rem;
        }
    }
}

.home4{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100vh;
    align-items: center;
    background-color: $second;

    >div{
        background-color: $third;
        width: 70%;
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 200px 0 0 200px;
        color: $first;
        gap: 4rem;
        font-size: 2rem;

        > h1{
            border-bottom: 5px solid $first;

        }
        
        >article{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;
            

            >div{
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: $dark;
                padding: 1.5rem;
                border-radius: 50%;
                animation: imgUp 0.7s linear infinite alternate ;
                
                
                >svg{
                    width: 4rem;
                    height: 4rem;
                }
                p{
                    font-size: 1rem;
                }

                &:hover{
                    filter: invert(1);
                    cursor: pointer;
                }
            }
        }
    }
}