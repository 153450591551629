@media screen and (max-width:880px){
    .home3{
        font-size: 0.8rem;
        margin-top: 0px;
        height: 140vh;
        >div{
            padding: 1rem;
            height: 120vh;
            gap: 0rem;
            padding: 1rem;
            >p{
                line-height: 100%;
                padding: 1rem;
            }
        }
    }
}

@media screen and (max-width:880px){
    .home4>div>article{
        gap: 1rem;
        >div{
            padding: 1rem;
            >svg{
                width: 2rem;
                height: 2rem;
            }
        }

    }
}

@media screen and (max-width:768px) {
    .contact{
        >main{
            width: 90%;
            border-radius: 0px;
            text-align: center;
        }
        >main>form>input{
            width: 90%;
        }
    }
    
}

@media screen and (max-width:670px){
    .home2{
        flex-direction: column;
        >img{
            width: 60%;
        }
        >div{
            width: 100%;

        }
    }
    .home4>div{
        width: 80%;
        font-size: 1rem;
    }
    .home4>div>article{
        gap: 1rem;
        >div{
            padding: 0.5rem;
            >svg{
                width: 2rem;
                height: 2rem;
            }
            >p{
                font-size: 0.8rem;
            }
        }

    }

    .home3>div{
        width: 90%;
        height: 140vh;
        border-radius: 0px 150px 150px 0px;
    }
}

@media screen and (max-width:488px){
    .home3>div{
        height: 160vh;
        
    }
    .home4{
        height: 250vh;
    }
}

@media  screen and (max-width:376px) {
    
    .home3>div{
        width: 90%;
    }
    .home4>div{
        width: 90%;
        font-size: 1rem;
    }
    
}